.btn-watch-video {
	font-family: "Open Sans", sans-serif;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 1px;
	display: inline-flex;
	align-items: center;
	padding: 10px 25px;
	margin-left: 10px;
	border-radius: 50px;
	transition: 0.5s;
	color: #5777ba;
	background: #fff;
	border: 2px solid #fff;
}

.btn-watch-video i {
	font-size: 32px;
	margin-right: 8px;
	line-height: 0;
}

.btn-watch-video:hover {
	background: transparent;
	color: #fff;
}
